export const isPremiumCustomer = () => {
  // if domain not equal to "https://store.rocketbuy.in" means premium
  // check url and indentify premium or non-premium customer
  return window.origin !== process.env.REACT_APP_IGNORE_DOMAIN_URL;
};

export const routePath = (merchantCode: string, path: string) => {
  const mCode =
    merchantCode === undefined ||
    merchantCode === "undefined" ||
    merchantCode === null
      ? ""
      : merchantCode;
  return isPremiumCustomer()
    ? path
    : mCode
    ? path.replace(`/`, `/${mCode}/`)
    : path;
};
