import * as React from "react";
import { useEffect } from "react";
import { useLocalStorage } from "react-use";
import { RF_ORDER } from "../../constants/storage.constant";
import { RocketbuyGetOrderSummaryApiResponse } from "../../models/RocketbuyGetOrderSummaryApiResponse";
import { UserAddressInfo } from "../../models/UserAddressInfo";

type TOrder = {
  deliveryAddress: UserAddressInfo;
  products: { [key: string]: number };
};

type OrderContextState = {
  loading: boolean;
  order: TOrder;
  paymentOption: "ONLINE" | "COD";
  summary: RocketbuyGetOrderSummaryApiResponse;
  comment: string;
  setComment: (comment: string) => void;
  prescriptions: File[];
  setPrescriptions: (files: File[]) => void;
  setSummary: (summary: RocketbuyGetOrderSummaryApiResponse) => void;
  changePaymentOption: (option: "ONLINE" | "COD") => void;
  selectDeliveryAddress: (address: UserAddressInfo) => void;
};

type OrderAction =
  | {
      type: "ORDER_REQUEST";
    }
  | {
      type: "ORDER_SUCCESS";
      payload: any;
    }
  | {
      type: "ORDER_FAIL";
      payload: any;
    }
  | {
      type: "ORDER_DELIVERY_ADDRESS";
      payload: UserAddressInfo;
    }
  | {
      type: "ORDER_PAYMENT_OPTION";
      payload: "ONLINE" | "COD";
    }
  | {
      type: "SUMMARY";
      payload: RocketbuyGetOrderSummaryApiResponse;
    }
  | {
      type: "ORDER_NOTES";
      payload: string;
    }
  | {
      type: "ORDER_FILES";
      payload: File[];
    };

type OrderProviderProps = { children: React.ReactNode };

const initialOrderState: OrderContextState = {
  loading: false,
  order: {
    deliveryAddress: {},
    products: {},
  },
  paymentOption: "ONLINE",
  changePaymentOption: (option: "ONLINE" | "COD") => {},
  selectDeliveryAddress: (address: UserAddressInfo) => {},
  summary: {},
  setSummary: (summary: RocketbuyGetOrderSummaryApiResponse) => {},
  comment: "",
  setComment: (comment: string) => {},
  prescriptions: [],
  setPrescriptions: (files: File[]) => {},
};

const OrderStateContext =
  React.createContext<OrderContextState>(initialOrderState);

OrderStateContext.displayName = "OrderContext";

function orderContextReducer(
  state: OrderContextState = initialOrderState,
  action: OrderAction
) {
  switch (action.type) {
    case "ORDER_REQUEST": {
      return {
        ...state,
      };
    }
    case "ORDER_SUCCESS": {
      return {
        ...state,
      };
    }
    case "ORDER_FAIL": {
      return {
        ...state,
      };
    }
    case "ORDER_DELIVERY_ADDRESS": {
      return {
        ...state,
        order: { ...state.order, deliveryAddress: action.payload },
      };
    }
    case "ORDER_PAYMENT_OPTION": {
      return {
        ...state,
        paymentOption: action.payload,
      };
    }
    case "SUMMARY": {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case "ORDER_NOTES": {
      return {
        ...state,
        comment: action.payload,
      };
    }
    case "ORDER_FILES": {
      return {
        ...state,
        prescriptions: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function OrderContextProvider({ children }: OrderProviderProps) {
  const [localOrder, setLocalOrder, remove] = useLocalStorage<TOrder>(RF_ORDER);
  const [state, dispatch] = React.useReducer(
    orderContextReducer,
    initialOrderState
  );

  useEffect(() => {
    const run = async () => {
      if (localOrder) {
        dispatch({
          type: "ORDER_DELIVERY_ADDRESS",
          payload: localOrder.deliveryAddress,
        });
      }
    };
    run();
  }, []);

  const selectDeliveryAddress = (address: UserAddressInfo) => {
    dispatch({ type: "ORDER_DELIVERY_ADDRESS", payload: address });
    setLocalOrder({
      ...state.order,
      deliveryAddress: { ...address },
    });
  };

  const changePaymentOption = (option: "ONLINE" | "COD") =>
    dispatch({ type: "ORDER_PAYMENT_OPTION", payload: option });

  const setSummary = (summary: RocketbuyGetOrderSummaryApiResponse) =>
    dispatch({ type: "SUMMARY", payload: summary });

  const setComment = (comment: string) =>
    dispatch({ type: "ORDER_NOTES", payload: comment });

  const setPrescriptions = (files: File[]) =>
    dispatch({ type: "ORDER_FILES", payload: files });

  const values = React.useMemo(
    () => ({
      ...state,
      selectDeliveryAddress,
      changePaymentOption,
      setSummary,
      setComment,
      setPrescriptions,
    }),
    [state]
  );

  return (
    <OrderStateContext.Provider value={{ ...values }}>
      {children}
    </OrderStateContext.Provider>
  );
}

function useOrder() {
  const context = React.useContext(OrderStateContext);
  if (context === undefined) {
    throw new Error("useHome must be used within a OrderContextProvider");
  }
  return context;
}
export { OrderContextProvider, useOrder };
