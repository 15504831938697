import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import React, { useEffect, useState } from "react";
import { StoreInfo } from "../../../models/StoreInfo";
import RocketBuyLogo from "../../../assets/images/rocketbuy-logo.svg";
import clsx from "clsx";
import { RF_SELECTED_STORE } from "../../../constants/storage.constant";

const StoreList: React.FC<{
  stores: O.Option<StoreInfo[]>;
  onStoreSelect: (store: StoreInfo) => void;
}> = ({ stores, onStoreSelect }) => {
  const [selectedStoreId, setSelectedStoreId] = useState("");

  useEffect(() => {
    const selectedStoreString = localStorage.getItem(RF_SELECTED_STORE);
    if (selectedStoreString) {
      const st: StoreInfo = JSON.parse(selectedStoreString);
      setSelectedStoreId(st.storeId);
    }
  }, []);

  return pipe(
    stores,
    O.map((stores) => (
      <>
        {stores.map((store) => (
          <div
            key={store.storeId}
            className={clsx(
              store.storeId === selectedStoreId
                ? `store-1 py-2   bg-gray-200 rounded flex pl-4 cursor-pointer`
                : ` store-1 py-2 border-b border-gray-200 flex ml-4 cursor-pointer`
            )}
            onClick={() => onStoreSelect(store)}
          >
            {store.logo && (
              <img
                className="store-img text-xs mt-1.5 h-10 w-10 "
                src={store.logo}
                alt="store-img"
              />
            )}
            {!store.logo && (
              <img
                className="store-img text-xs mt-1.5 h-10 w-10 "
                src={RocketBuyLogo}
                alt="store-img"
              />
            )}
            <div className="store-address-content ml-4">
              <h1 className="text-base h-6 text-left overflow-hidden overflow-ellipsis font-semibold text-gray-600">
                {store.name}
              </h1>
              <p className="text-xs leading-tight h-8 mb-1 text-left overflow-hidden overflow-ellipsis text-gray-500">
                {store.address}
              </p>
            </div>
          </div>
        ))}
      </>
    )),
    O.toNullable
  );
};

export default StoreList;
