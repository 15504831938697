export enum NextScreenEnum {
  SIGNUP = "SIGNUP",
  VERIFYMOBILE = "VERIFY_MOBILE",
  VERIFYEMAIL = "VERIFY_EMAIL",
  ENTERUSERDETAILS = "ENTER_USER_DETAILS",
  SELECTACCOUNT = "SELECT_ACCOUNT",
  SELECTLOGINACCOUNT = "SELECT_LOGIN_ACCOUNT",
  SELECTSIGNUPACCOUNT = "SELECT_SIGNUP_ACCOUNT",
  SERVICEPREFERENCES = "SERVICE_PREFERENCES",
  ADDPLACE = "ADD_PLACE",
  HOME = "HOME",
  LOGIN = "LOGIN",
  MYPROFILE = "MY_PROFILE",
  CHANGEMOBILE = "CHANGE_MOBILE",
  NEWPASSWORD = "NEW_PASSWORD",
  SIGNIN = "SIGNIN",
  ENTERPASSWORD = "ENTER_PASSWORD",
  SELECTROLE = "SELECT_ROLE",
  CONFIGUREBUSINESS = "CONFIGURE_BUSINESS",
}
