import * as React from "react";
import { useEffect } from "react";
import { RF_ADDRESS_FOOTER } from "../../constants/storage.constant";
import { useLocalStorage } from "react-use";

type MODAL_VIEWS =
  | "ADD_ADDRESS_VIEW"
  | "EDIT_ADDRESS_VIEW"
  | "THANK_YOU_VIEW"
  | "STORE_LIST_VIEW";

type AppAction =
  | {
      type: "SET_LOADING";
      value: boolean;
    }
  | {
      type: "OPEN_MODAL";
    }
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "SET_MODAL_VIEW";
      view: MODAL_VIEWS;
    }
  | { type: "LOGIN_ACTION_FROM"; value: "CART-PLACE-ORDER" | "MORE-LINK" }
  | { type: "SET_STORE_ACCESS"; value: string }
  | {
      type: "CHANGE_ADDRESS_FOOTER";
      payload: boolean;
    }
  | {
      type: "SET_UPDATE_ADDRESS";
      payload: boolean;
    }
  | {
      type: "SET_STORE_MODAL_VIEW";
      payload: boolean;
    };
//type Dispatch = (action: AppAction) => void;
type AppContextState = {
  currentStoreAccessId: string;
  storeModalView: boolean;
  addressFooter: boolean;
  updateAddress: boolean;
  loading: boolean;
  authenticated: boolean;
  modalView: string;
  displayModal: boolean;
  loginActionFrom: "CART-PLACE-ORDER" | "MORE-LINK";
  setStoreModalView: (storeModalView: boolean) => void;
  changeAddressFooter: (addressFooter: boolean) => void;
  setUpdateAddress: (updateAddress: boolean) => void;
  openModal: () => void;
  closeModal: () => void;
  setModalView: (view: MODAL_VIEWS) => void;
  loginFromCart: () => void;
  loginFromMore: () => void;
  setStorAccessId: (storeAccessId: string) => void;
};
type AppProviderProps = { children: React.ReactNode };

const initialState: AppContextState = {
  currentStoreAccessId: "",
  storeModalView: false,
  addressFooter: false,
  updateAddress: false,
  authenticated: false,
  loading: false,
  modalView: "ADD_ADDRESS_VIEW",
  displayModal: false,
  loginActionFrom: "CART-PLACE-ORDER",
  setStoreModalView: (storeModalView: boolean) => {},
  changeAddressFooter: (addressFooter: any) => {},
  setUpdateAddress: (updateAddress: boolean) => {},
  openModal: () => {},
  closeModal: () => {},
  setModalView: (view: MODAL_VIEWS) => {},
  loginFromCart: () => {},
  loginFromMore: () => {},
  setStorAccessId: (storeAccessId: string) => {},
};

const AppStateContext = React.createContext<AppContextState>(initialState);

AppStateContext.displayName = "AppContext";

function appContextReducer(state: AppContextState, action: AppAction) {
  switch (action.type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.value,
      };
    }
    case "SET_MODAL_VIEW": {
      return {
        ...state,
        modalView: action.view,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        displayModal: true,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        displayModal: false,
      };
    }
    case "LOGIN_ACTION_FROM": {
      return {
        ...state,
        loginActionFrom: action.value,
      };
    }
    case "SET_STORE_ACCESS": {
      return {
        ...state,
        currentStoreAccessId: action.value,
      };
    }
    case "CHANGE_ADDRESS_FOOTER": {
      return {
        ...state,
        addressFooter: action.payload,
      };
    }
    case "SET_UPDATE_ADDRESS": {
      return {
        ...state,
        updateAddress: action.payload,
      };
    }
    case "SET_STORE_MODAL_VIEW": {
      return {
        ...state,
        storeModalView: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function AppContextProvider({ children }: AppProviderProps) {
  const [localAddressFooter, setLocalAddressFooter] =
    useLocalStorage<boolean>(RF_ADDRESS_FOOTER);
  const [state, dispatch] = React.useReducer(appContextReducer, initialState);

  useEffect(() => {
    if (localAddressFooter) {
      dispatch({
        type: "CHANGE_ADDRESS_FOOTER",
        payload: localAddressFooter,
      });
    }
  }, []);
  useEffect(() => {
    setLocalAddressFooter(state.addressFooter);
  }, [state.addressFooter, setLocalAddressFooter]);

  const openModal = () => dispatch({ type: "OPEN_MODAL" });
  const closeModal = () => dispatch({ type: "CLOSE_MODAL" });
  const setModalView = (view: MODAL_VIEWS) =>
    dispatch({ type: "SET_MODAL_VIEW", view });

  const loginFromCart = () =>
    dispatch({ type: "LOGIN_ACTION_FROM", value: "CART-PLACE-ORDER" });
  const loginFromMore = () =>
    dispatch({ type: "LOGIN_ACTION_FROM", value: "MORE-LINK" });

  const setStorAccessId = (storeAccessId: string) =>
    dispatch({ type: "SET_STORE_ACCESS", value: storeAccessId });

  const changeAddressFooter = (option: boolean) => {
    dispatch({ type: "CHANGE_ADDRESS_FOOTER", payload: option });
  };

  const setUpdateAddress = (option: boolean) => {
    dispatch({ type: "SET_UPDATE_ADDRESS", payload: option });
  };
  const setStoreModalView = (option: boolean) => {
    dispatch({ type: "SET_STORE_MODAL_VIEW", payload: option });
  };

  const values = React.useMemo(
    () => ({
      ...state,
      setModalView,
      openModal,
      closeModal,
      loginFromCart,
      loginFromMore,
      setStorAccessId,
      changeAddressFooter,
      setUpdateAddress,
      setStoreModalView,
    }),
    [state]
  );
  return (
    <AppStateContext.Provider value={{ ...values }}>
      {children}
    </AppStateContext.Provider>
  );
}

function useApp() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppContextProvider");
  }
  return context;
}
export { AppContextProvider, useApp };
