import { FC, useRef, useEffect } from "react";
import Portal from "@reach/portal";
import s from "./Modal.module.css";
import Cross from "../icons/cross";
import { useApp } from "../../context/app-context";
import StoreLogo from "../../../assets/images/rocketbuy-logo.svg";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

interface Props {
  className?: string;
  children?: any;
  open?: boolean;
  onClose: () => void;
}

const Modal: FC<Props> = ({ children, open, onClose }) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { storeModalView } = useApp();
  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current);
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open]);

  return (
    <Portal>
      {open ? (
        <div className={s.root} ref={ref}>
          <div className={` ${s.modal} w-10/12 lg:w-5/12`}>
            {storeModalView && (
              <div className=" w-full">
                <div className="logo-and-cross flex justify-center relative">
                  <div className="logo">
                    <img className="h-12 w-12" src={StoreLogo} alt="" />
                  </div>
                  <button
                    onClick={() => onClose()}
                    aria-label="Close panel"
                    className="hover:text-gray-500 absolute top-0 right-0 transition ease-in-out duration-150 focus:outline-none"
                  >
                    <Cross className="h-6 text-gray-500 w-6" />
                  </button>
                </div>
                <div>
                  <h1 className="text-xl  pt-2 pb-4 text-semibold text-center w-10/12 mx-auto text-gray-700">
                    Select the store you want to shop from!
                  </h1>
                </div>
              </div>
            )}
            {!storeModalView && (
              <div className="h-7 flex items-center justify-end w-full">
                <button
                  onClick={() => onClose()}
                  aria-label="Close panel"
                  className="hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none"
                >

                  <Cross className="h-6 text-gray-500 w-6" />
                </button>
              </div>
            )}
            {storeModalView && (
              <div className="h-72 overflow-y-auto hide-scrollbar-custom">
                {children}
              </div>
            )}
            {!storeModalView && <div> {children}</div>}
            {/* {children} */}
          </div>
        </div>
      ) : null}
    </Portal>
  );
};

export default Modal;
