import React, { lazy, useEffect, useState } from "react";
import {
  Route,
  useParams,
  Outlet,
  Routes,
  useNavigate,
} from "react-router-dom";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import {
  RF_CARTITEMS,
  RF_MERCHANT,
  RF_MERCHANT_CODE,
  RF_ORDER,
  RF_USER_INFO,
} from "../../constants/storage.constant";

import NonPremiumRoutes from "../../routes/non-premium-routes";
import PremiumRoutes from "../../routes/premium-routes";
import rocketbuyCustomerService from "../../services/rocketbuy.customer.service";
import { isPremiumCustomer, routePath } from "../../utils/route.utils";
import {
  getMarchantCode,
  getMarchantStoreKey,
  parseMerchant,
} from "../../utils/store.utils";
import Modal from "../common/modal/Modal";
import { useCart } from "../context/cart-context";
import { useApp } from "../context/app-context";
import { useHome } from "../context/home-context";

// const Store = lazy(() => import("./store"));

const AddAddressPage = lazy(() => import("../../pages/add-address"));
const ThankYouPopUp = lazy(
  () => import("../../components/common/thankyou-popup/thankyou-popup")
);
const HomeStoreListView = lazy(
  () => import("../home-store-list/home-store-list")
);

const App: React.FC = () => {
  const { merchantCode = "" } = useParams();
  const { displayModal, closeModal, modalView } = useApp();
  const { store } = useHome();
  const { removeCartItems } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("1. window.origin: ", window.origin);

    if (isPremiumCustomer()) {
      // this for premium client local testing  ...
      const localDevTestingClient =
        process.env.NODE_ENV != "production" ? "https://www.hcmeds.in" : "";

      // If premium customer then call this function
      const run = async () => {
        const storeResponse =
          await rocketbuyCustomerService.storeKeyUsingDomain(
            localDevTestingClient || window.origin
          );
        console.log(`storeResponse: `, storeResponse);
        if (storeResponse.successful) {
          const merchant = parseMerchant(storeResponse);
          localStorage.setItem(RF_MERCHANT, JSON.stringify(merchant));
          const savedMerchant = getMarchantCode();
          if (savedMerchant !== getMarchantStoreKey()) {
            //empty cart items
            //localStorage.setItem(RF_CARTITEMS, "[]");
            removeCartItems();
            localStorage.removeItem(RF_ORDER);
            localStorage.removeItem(RF_USER_INFO);
          }

          if (storeResponse.multiMerchant) {
            // when multistore then save selected store
            // localStorage.setItem(
            //   RF_SELECTED_STORE,
            //   JSON.stringify(merchant.stores[0]) // store very first element of mutli store
            // );
          }

          navigate(`/home`, { replace: true });

          //setLoading(false);
          //setMultiMerchant(
          //storeResponse.multiMerchant ? storeResponse.multiMerchant : false
          //);
        } else {
          //setLoading(false);
          navigate("/not-found"); // go to close store.
        }
      };
      run();
    }
  }, []);

  return (
    <React.Fragment>
      <div
        className={
          store.status == null || store.status === "CLOSED"
            ? "filter grayscale"
            : " "
        }
      >
        {isPremiumCustomer() ? <PremiumRoutes /> : <NonPremiumRoutes />}
        <Modal open={displayModal} onClose={closeModal}>
          {modalView === "ADD_ADDRESS_VIEW" && <AddAddressPage />}
          {modalView === "THANK_YOU_VIEW" && <ThankYouPopUp />}
          {modalView === "STORE_LIST_VIEW" && <HomeStoreListView />}
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default App;
