import SpinnerLoader from "../../../assets/images/Spinner-loader.svg";
import  { FC } from "react";

type TLoaderProps = {
  size?:string;
}

const Loader : FC<TLoaderProps> = ({size="h-10 w-10"}) => {
  return (
    <div className="flex justify-center">
      <img
        className={`animate-spin rotate-180 ${size}`}
        src={SpinnerLoader}
        alt={"loading"}
      />
    </div>
  );
};

export default Loader;
