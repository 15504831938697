import { boolean } from "fp-ts";
import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLocalStorage } from "react-use";
import { RF_CARTITEMS } from "../../constants/storage.constant";

export type CartItem = {
  productId: string;
  name: string;
  image: string;
  price: number;
  countInStock: number;
  quantity: number;
  sellingPrice: number;
};

export type CartContextState = {
  cartItems: CartItem[];
  promoValue: string;
  discount: number;
  deliveryOption: "PICKUP" | "DELIVERY";
  couponOption: "VALID_COUPON" | "INVALID_COUPON";
  shippingAddress: {};
  isShoppingCartOpen: boolean;
  openCloseShoppingCart: (open: boolean) => void;
  addToCart: (product: CartItem) => void;
  removeFromCart: (productId: string) => void;
  removeCartItems: () => void;
  changeDeliveryOption: (option: "PICKUP" | "DELIVERY") => void;
  changeCouponOption: (option: "VALID_COUPON" | "INVALID_COUPON") => void;
  changeDiscount: (discount: any) => void;
  changePromoValue: (promoValue: any) => void;
};

export type CartAction =
  | {
      type: "CART_ADD_ITEM";
      payload: CartItem;
    }
  | {
      type: "CART_REMOVE_ITEM";
      payload: string; // remove item by product id
    }
  | {
      type: "CART_CLEAR_ITEMS";
      payload: any;
    }
  | {
      type: "CART_INITIALIZE_ITEMS";
      payload: CartItem[];
    }
  | {
      type: "CART_DEVELIVERY_OPTION";
      payload: "PICKUP" | "DELIVERY";
    }
  | {
      type: "COUPON_OPTION";
      payload: "VALID_COUPON" | "INVALID_COUPON";
    }
  | {
      type: "DISCOUNT_OPTION";
      payload: number;
    }
  | {
      type: "PROMO_VALUE_OPTION";
      payload: string;
    }
  | {
      type: "SHOPPING_CART";
      payload: boolean;
    };

type CartProviderProps = { children: React.ReactNode };

const initialCartState: CartContextState = {
  cartItems: [],
  promoValue: "",
  discount: 0,
  deliveryOption: "PICKUP",
  couponOption: "INVALID_COUPON",
  shippingAddress: {},
  isShoppingCartOpen: false,
  openCloseShoppingCart: (open: boolean) => {},
  addToCart: (product: CartItem) => {},
  removeFromCart: (productId: string) => {},
  removeCartItems: () => {},
  changeDeliveryOption: (option: "PICKUP" | "DELIVERY") => {},
  changeCouponOption: (option: "VALID_COUPON" | "INVALID_COUPON") => {},
  changeDiscount: (discount: any) => {},
  changePromoValue: (promoValue: any) => {},
};

const CartStateContext =
  React.createContext<CartContextState>(initialCartState);

CartStateContext.displayName = "CartContext";

export function cartContextReducer(
  state: CartContextState = initialCartState,
  action: CartAction
) {
  switch (action.type) {
    case "CART_ADD_ITEM": {
      const item = action.payload;
      const existItem = state.cartItems.find(
        (x) => x.productId === item.productId
      );

      if (existItem) {
        const qty = existItem.quantity;
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.productId === existItem.productId
              ? { ...existItem, quantity: qty + 1 }
              : x
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    }
    case "CART_REMOVE_ITEM": {
      const itemId = action.payload;
      const existItem = state.cartItems.find((x) => x.productId === itemId);

      if (existItem) {
        const qty = existItem.quantity;
        if (qty === 1) {
          return {
            ...state,
            cartItems: state.cartItems.filter((x) => x.productId !== itemId),
          };
        } else {
          return {
            ...state,
            cartItems: state.cartItems.map((x) =>
              x.productId === existItem.productId
                ? { ...existItem, quantity: qty - 1 }
                : x
            ),
          };
        }
      } else {
        return {
          ...state,
          // cartItems: state.cartItems.filter((x) => x.productId !== itemId),
        };
      }
    }
    case "CART_CLEAR_ITEMS": {
      return {
        ...state,
        cartItems: [],
      };
    }
    case "CART_INITIALIZE_ITEMS": {
      return {
        ...state,
        cartItems: [...action.payload],
      };
    }
    case "CART_DEVELIVERY_OPTION": {
      return {
        ...state,
        deliveryOption: action.payload,
      };
    }
    case "COUPON_OPTION": {
      return {
        ...state,
        couponOption: action.payload,
      };
    }
    case "DISCOUNT_OPTION": {
      return {
        ...state,
        discount: action.payload,
      };
    }
    case "PROMO_VALUE_OPTION": {
      return {
        ...state,
        promoValue: action.payload,
      };
    }
    case "SHOPPING_CART": {
      return {
        ...state,
        isShoppingCartOpen: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

// const usePersistCartReducer = (
//   LOCAL_STORAGE_KEY: string,
//   INITIAL_STATE: any
// ) => {
//   const [savedState, saveState] = useLocalStorage(
//     LOCAL_STORAGE_KEY,
//     INITIAL_STATE
//   );
//   const reducerLocalStorage = React.useCallback(
//     // give `reducerLocalStorage` the same TS API
//     // as the underlying `reducer` function
//     (state: CartContextState, action: CartAction): CartContextState => {
//       const newState = cartContextReducer(state, action);

//       saveState(newState);

//       return newState;
//     },
//     [saveState]
//   );

//   return React.useReducer(reducerLocalStorage, savedState);
// };

function CartContextProvider({ children }: CartProviderProps) {
  const { merchantCode = "" } = useParams();
  const [localCartItems, setLocalCartItems, removeCart] =
    useLocalStorage<CartItem[]>(RF_CARTITEMS);

  const [state, dispatch] = React.useReducer(
    cartContextReducer,
    initialCartState
  );

  useEffect(() => {
    if (localCartItems) {
      dispatch({
        type: "CART_INITIALIZE_ITEMS",
        payload: localCartItems,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalCartItems(state.cartItems);
  }, [state.cartItems, setLocalCartItems]);

  const addToCart = (product: CartItem) => {
    dispatch({ type: "CART_ADD_ITEM", payload: product });
  };

  const removeFromCart = (productId: string) => {
    dispatch({ type: "CART_REMOVE_ITEM", payload: productId });
  };

  const removeCartItems = () => {
    removeCart();
    dispatch({
      type: "CART_INITIALIZE_ITEMS",
      payload: [],
    });
  };

  const changeDeliveryOption = (option: "PICKUP" | "DELIVERY") =>
    dispatch({ type: "CART_DEVELIVERY_OPTION", payload: option });

  const changeCouponOption = (option: "VALID_COUPON" | "INVALID_COUPON") =>
    dispatch({ type: "COUPON_OPTION", payload: option });

  const changeDiscount = (option: number) => {
    dispatch({ type: "DISCOUNT_OPTION", payload: option });
  };
  const changePromoValue = (option: string) => {
    dispatch({ type: "PROMO_VALUE_OPTION", payload: option });
  };

  const openCloseShoppingCart = (open: boolean) =>
    dispatch({ type: "SHOPPING_CART", payload: open });

  const values = React.useMemo(
    () => ({
      ...state,
      addToCart,
      removeFromCart,
      removeCartItems,
      changeDeliveryOption,
      changeCouponOption,
      changeDiscount,
      changePromoValue,
      openCloseShoppingCart,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );
  return (
    <CartStateContext.Provider value={{ ...values }}>
      {children}
    </CartStateContext.Provider>
  );
}

function useCart() {
  const context = React.useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useHome must be used within a CartContextProvider");
  }
  return context;
}
export { CartContextProvider, useCart };
