export enum RoutePaths {
  Root = "/",
  MerchantRoot = "/:merchantCode",
  NotFound = "/not-found",
  Home = `/home`,
  Address = `/address`,
  Cart = `/cart`,
  Categories = `/categories`,
  CheckoutDetail = `/checkout-detail`,
  WebCart = `/web-cart`,
  WebAccount = "/web-account",
  WebCheckout = `/web-checkout`,
  WebDeliveryAddress = "/web-delivery-address",
  Login = `/login`,
  VerifyMobile = `/verify_mobile`,
  Orders = `/orders`,
  OrderDetails = `/order-details/:orderId`,
  SelectStore = `/select-store`,
  OrderSummary = `/order-summary`,
  Products = `/products`,
  ProductDetails = `/product-details/:productName`,
  //ProductDetails = `/:categoryName/:productName`,
  Profile = `/profile`,
  SignUp = "/signup",
  RefundAndCancellation = `/refund-and-cancellation`,
  TermsAndConditions = `/terms-and-conditions`,
  PrivacyPolicy = `/privacy-policy`,
  Checkout = `/checkout`,
}
