import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./components/app";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./components/context/app-context";
import { errorInterceptor } from "./utils/error-interceptor";
import { HomeContextProvider } from "./components/context/home-context";
import { ProductContextProvider } from "./components/context/product-context";
import { OrderContextProvider } from "./components/context/order-context";
import { UserContextProvider } from "./components/context/user-context";
import { CartContextProvider } from "./components/context/cart-context";
import Loader from "./components/common/loader/loader";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";
import "@reach/dialog/styles.css";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./react-query/queryClient";

errorInterceptor();

ReactDOM.render(
  <React.StrictMode>
    <Toaster />
    <Suspense
      fallback={
        <div className="mt-72">
          <Loader size={"h-20 w-20"} />
        </div>
      }
    >
      <Router>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <AppContextProvider>
              <HomeContextProvider>
                <ProductContextProvider>
                  <OrderContextProvider>
                    <UserContextProvider>
                      <CartContextProvider>
                        <App />
                      </CartContextProvider>
                    </UserContextProvider>
                  </OrderContextProvider>
                </ProductContextProvider>
              </HomeContextProvider>
            </AppContextProvider>
          </HelmetProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
