import React, { lazy, useEffect, useState } from "react";

import {
  Route,
  useParams,
  Outlet,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

import {
  RF_CARTITEMS,
  RF_MERCHANT,
  RF_MERCHANT_CODE,
  RF_ORDER,
  RF_USER_INFO,
} from "../constants/storage.constant";
import rocketbuyCustomerService from "../services/rocketbuy.customer.service";
import { RoutePaths } from "./route-path";

import Loader from "../components/common/loader/loader";
import { SelectStorePopup } from "../components/common/select-store-popup";
import { useCart } from "../components/context/cart-context";
import { getMarchantCode, parseMerchant } from "../utils/store.utils";
const PrivacyPolicyPage = lazy(() => import("../pages/privacy-policy-page"));
const RefundAndCancellationPage = lazy(
  () => import("../pages/refund-and-cancellation-page")
);
const SelectStorePage = lazy(() => import("../pages/select-store-page"));
const TermsAndConditionsPage = lazy(
  () => import("../pages/terms-and-conditions-page")
);
const StoreClosed = lazy(() => import("../components/app/store-closed"));

const HomePage = lazy(() => import("../pages/home-page"));

const CategoriesPage = lazy(() => import("../pages/categories-page"));
const CheckOutDetailPage = lazy(
  () => import("../pages/check-out-details-page")
);
// const WebCartPage = lazy(() => import("../pages/web-cart-page"));
const WebAccountPage = lazy(() => import("../pages/web-account"));
const WebCheckout = lazy(() => import("../pages/web-checkout"));
const WebDeliveryAddress = lazy(() => import("../pages/web-delivery-address"));

const ProductListPage = lazy(() => import("../pages/product-list-page"));
const ProductDetailPage = lazy(() => import("../pages/product-detail-page"));

const LoginPage = lazy(() => import("../pages/login-page"));
const SignUpPage = lazy(() => import("../pages/signup-page"));
const OrdersPage = lazy(() => import("../pages/orders-page"));
const OrderDetailPage = lazy(() => import("../pages/order-detail-page"));
const OrderSummaryPage = lazy(() => import("../pages/order-summary-page"));

const MobileVerificationPage = lazy(
  () => import("../pages/mobile-verification-page")
);

const AddressPage = lazy(() => import("../pages/address-page"));

const CartPage = lazy(() => import("../pages/cart-page"));
const ProfilePage = lazy(() => import("../pages/profile-page"));
const CheckoutPage = lazy(() => import("../pages/checkout-page"));

const NonPremiumRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.Root} element={<StoreClosed />} />
      <Route path={RoutePaths.MerchantRoot} element={<InitMerchant />}>
        <Route
          path={RoutePaths.Root}
          element={<SelectStorePopup showModal />}
        />
        <Route path={RoutePaths.Home} element={<HomePage />} />
        <Route path={RoutePaths.Address} element={<AddressPage />} />
        <Route path={RoutePaths.Cart} element={<CartPage />} />
        <Route path={RoutePaths.Categories} element={<CategoriesPage />} />
        <Route
          path={RoutePaths.CheckoutDetail}
          element={<CheckOutDetailPage />}
        />
        <Route path={RoutePaths.Checkout} element={<CheckoutPage />} />
        {/* <Route path={RoutePaths.WebCart} element={<WebCartPage />} /> */}
        <Route path={RoutePaths.WebAccount} element={<WebAccountPage />} />
        <Route path={RoutePaths.WebCheckout} element={<WebCheckout />} />
        <Route
          path={RoutePaths.WebDeliveryAddress}
          element={<WebDeliveryAddress />}
        />
        <Route path={RoutePaths.Login} element={<LoginPage />} />
        <Route
          path={RoutePaths.VerifyMobile}
          element={<MobileVerificationPage />}
        />
        <Route path={RoutePaths.Orders} element={<OrdersPage />} />
        <Route path={RoutePaths.OrderDetails} element={<OrderDetailPage />} />
        <Route path={RoutePaths.SelectStore} element={<SelectStorePage />} />
        <Route path={RoutePaths.OrderSummary} element={<OrderSummaryPage />} />
        <Route path={RoutePaths.Products} element={<ProductListPage />} />
        <Route
          path={RoutePaths.ProductDetails}
          element={<ProductDetailPage />}
        />
        <Route path={RoutePaths.Profile} element={<ProfilePage />} />

        <Route path={RoutePaths.SignUp} element={<CheckOutDetailPage />} />

        <Route
          path={RoutePaths.RefundAndCancellation}
          element={<RefundAndCancellationPage />}
        />
        <Route
          path={RoutePaths.TermsAndConditions}
          element={<TermsAndConditionsPage />}
        />
        <Route
          path={RoutePaths.PrivacyPolicy}
          element={<PrivacyPolicyPage />}
        />
      </Route>
    </Routes>
  );
};

function InitMerchant() {
  const { merchantCode = "" } = useParams();
  const location = useLocation();
  const { removeCartItems } = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [multiMerchant, setMultiMerchant] = useState(false);

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);
        const storeResponse = await rocketbuyCustomerService.storeKeyUsingGET(
          merchantCode,
          ""
        );

        if (storeResponse.successful) {
          localStorage.setItem(
            RF_MERCHANT,
            JSON.stringify(parseMerchant(storeResponse))
          );
          const savedMerchant = getMarchantCode();
          if (savedMerchant !== merchantCode) {
            //empty cart items
            //localStorage.setItem(RF_CARTITEMS, "[]");
            removeCartItems();
            localStorage.removeItem(RF_ORDER);
            localStorage.removeItem(RF_USER_INFO);
          }
          localStorage.setItem(RF_MERCHANT_CODE, merchantCode);
          if (!storeResponse.multiMerchant) {
            if (location.pathname.includes("product-details")) {
              // do nothing
            } else {
              navigate(`/${merchantCode}/home`, { replace: true });
            }
          }
          setLoading(false);
          //setMultiMerchant(
          //storeResponse.multiMerchant ? storeResponse.multiMerchant : false
          //);
        } else {
          setLoading(false);
          navigate(RoutePaths.Root); // go to close store.
        }
      } catch (error) {
        setLoading(false);
        navigate(RoutePaths.Root); // go to close store.
      }
    };

    run();
  }, [merchantCode]);

  if (loading) {
    return (
      <div className="mt-72">
        <Loader size={"h-20 w-20"} />
      </div>
    );
  }

  //if (multiMerchant) {
  //   return <SelectStorePopup showModal />;
  // }
  return <Outlet />;
}

export default NonPremiumRoutes;
